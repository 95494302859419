import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { Global, css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share'

import Theme, { Mq } from '../themes/default'
import Layout from '../organisms/layout'
import { H4 } from '../atoms/heading'
import { SmallTitle } from '../atoms/text'
import { Row, Column } from '../atoms/grid'
import SEO from '../organisms/seo'
import { FooterHero } from '../molecules/footer-hero'
import BlogPostMeta from '../molecules/blog-post-meta'
import { IContentfulBlogPost } from '../interfaces'

interface IBlogPostDataProps {
  contentfulBlogPost: IContentfulBlogPost
}

interface IBlogPostProps extends RouteComponentProps {
  data: IBlogPostDataProps
}

const BlogContentContainer = styled.div`
  padding: 7% 5%;

  ${Mq.md} {
    padding: 5% 3%;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }

  .SocialMediaShareButton {
    cursor: pointer;
  }
`

const BorderStyles = `
  width: 1px;
  height: 100%;
  background: ${rgba(Theme.colors.darkBlue, 0.1)};
  position: absolute;
  top: 0;
  display: none;
  z-index: -3;

  ${Mq.md} {
    display: block;
  }
`

const BorderLeft = styled.div`
  ${BorderStyles};
  left: 5%;

  ${Mq.xxl} {
    left: 15%;
  }
`

const BorderRight = styled.div`
  ${BorderStyles};
  right: 5%;

  ${Mq.xxl} {
    right: 15%;
  }
`

const BlogContentStyles = css`
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2.625rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }
  }

  blockquote p {
    background: ${Theme.colors.lightBrown};
    font-family: ${Theme.fonts.primary};
    padding: 1rem;
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 2rem;

    ${Mq.md} {
      padding: 2rem;
    }
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: 0.9375rem;
    line-height: 1.5625rem;

    ${Mq.md} {
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }

    li {
      margin-bottom: 1rem;
    }
  }

  a {
    color: ${Theme.colors.primary};
  }

  img {
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
    }
  }
`

const BlogShareSticky = styled.div`
  ${Mq.md} {
    position: sticky;
    top: 6rem;
  }
`

const BlogAuthor = styled(H4)`
  margin-bottom: 2rem;

  ${Mq.md} {
    margin-bottom: 4rem;
  }
`

const PublishDate = styled(SmallTitle)`
  color: ${rgba(Theme.colors.darkBlue, 0.5)};
`

const BlogPost = (props: IBlogPostProps) => {
  const { contentfulBlogPost } = props.data

  return (
    <Layout altBg={true} hideBorders={true}>
      <Global styles={BlogContentStyles} />
      <SEO
        description={contentfulBlogPost.seoDescription.seoDescription}
        image={contentfulBlogPost.featuredMedia.fluid as FluidObject}
        keywords={contentfulBlogPost.seoKeywords}
        title={contentfulBlogPost.seoTitle || contentfulBlogPost.title}
      />
      <BlogPostMeta
        title={contentfulBlogPost.title}
        featuredImage={contentfulBlogPost.featuredMedia}
      />

      <BorderLeft />
      <BorderRight />

      <Fade bottom={true} distance={Theme.fadeDistance}>
        <BlogContentContainer>
          <Row flexWrap="wrap">
            <Column
              order={[2, 2, 2, 1]}
              width={[1, 1, 1, 1 - ((1 + Math.sqrt(5)) / 2 - 1)]}
            >
              <BlogShareSticky>
                <SmallTitle>Author</SmallTitle>
                <BlogAuthor>{contentfulBlogPost.author.name}</BlogAuthor>

                <SmallTitle>Share</SmallTitle>
                <Row>
                  <Column pr={1}>
                    <FacebookShareButton url={props.location.href}>
                      <FacebookIcon />
                    </FacebookShareButton>
                  </Column>
                  <Column pr={1}>
                    <TwitterShareButton url={props.location.href}>
                      <TwitterIcon />
                    </TwitterShareButton>
                  </Column>
                  <Column pr={1}>
                    <LinkedinShareButton url={props.location.href}>
                      <LinkedinIcon />
                    </LinkedinShareButton>
                  </Column>
                  <Column pr={1}>
                    <EmailShareButton url={props.location.href}>
                      <EmailIcon />
                    </EmailShareButton>
                  </Column>
                </Row>
              </BlogShareSticky>
            </Column>

            <Column
              flex="1"
              order={[1, 1, 1, 2]}
              width={[1, 1, 1, (1 + Math.sqrt(5)) / 2 - 1]}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: contentfulBlogPost.body.childMarkdownRemark.html,
                }}
              />
              <div>
                <PublishDate>Updated: {contentfulBlogPost.publishDate}</PublishDate>
              </div>
            </Column>
          </Row>
        </BlogContentContainer>
      </Fade>

      <FooterHero />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MM/YYYY")
      author {
        name
        avatar {
          fixed(width: 100) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      featuredMedia {
        fluid(maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      seoKeywords
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`

export default BlogPost
