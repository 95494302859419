import React from 'react'
import Img from 'gatsby-image'
import { GatsbyImageProps } from 'gatsby-image'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

import { Row, Column } from '../atoms/grid'
import Theme, { Mq } from '../themes/default'
import { H1 } from '../atoms/heading'
import { SmallTitle } from '../atoms/text'
import { UnstyledGatsbyLink } from '../atoms/link'

import Waves from '../assets/images/waves.jpg'
import BackArrow from '../assets/images/back-arrow.svg'

interface IBlogPostMetaProps {
  featuredImage: GatsbyImageProps
  title: string
}

const BlogHeroRow = styled(Row)`
  height: 100%;
  align-items: center;
`

const BlogHeroTextContainer = styled.div`
  background: ${props => props.theme.colors.darkBlue};
  width: 95%;
  padding: 5%;
  top: -10rem;
  margin-bottom: -10rem;
  position: relative;

  ${Mq.md} {
    padding-top: 0;
    padding-bottom: 0;
    height: 300px;
    width: 95%;
    padding-right: 8%;
    padding-left: 3%;
  }
  ${Mq.xxl} {
    width: 85%;
    padding-right: 15%;
  }
`

const BlogHeroLinkText = styled(SmallTitle)`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  margin-bottom: 2rem;

  &:before {
    content: "";
    background-image: url(${BackArrow});
    width: 10px;
    height: 20px;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: bottom;
  }

  &:hover {
    text-decoration: underline;
  }

  ${Mq.md} {
    margin-bottom: 0;
  }
`

const BlogPostTitle = styled(H1)`
  color: ${Theme.colors.white};

  ${Mq.md} {
    font-size: 3vw;
  }
  ${Mq.xxl} {
    font-size: 2.6rem;
  }
`

const WavesContainer = styled.div`
  background-image: url(${Waves});
  background-repeat: no-repeat;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  height: 60vh;
  background-size: cover;
  z-index: -2;
`

const FeaturedImage = styled(Img)`
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  height: 60vh;
  z-index: -2;
`

const BlogPostMeta = (props: IBlogPostMetaProps) => {
  const { featuredImage, title } = props
  return (
    <div>
      <WavesContainer />
      <FeaturedImage fluid={featuredImage.fluid}/>

      <Fade right={true} distance={Theme.fadeDistance}>
        <BlogHeroTextContainer>
          <BlogHeroRow flexWrap="wrap" y={3,4,5}>
            <Column width={[1, 1, 1 - ((1 + Math.sqrt(5)) / 2 - 1)]}>
              <UnstyledGatsbyLink to="/blog">
                <BlogHeroLinkText>View All</BlogHeroLinkText>
              </UnstyledGatsbyLink>
            </Column>

            <Column flex="1" width={[1, 1, (1 + Math.sqrt(5)) / 2 - 1]}>
              <BlogPostTitle>{title}</BlogPostTitle>
            </Column>
          </BlogHeroRow>
        </BlogHeroTextContainer>
      </Fade>
    </div>
  )
}

export default BlogPostMeta
